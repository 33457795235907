import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import ReactGA from "react-ga";

const Office = window.Office;

// Google Analytics. NODE_ENV is set in the production build.
if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-100352253-5");
  ReactGA.set({ anonymizeIp: true });
} else {
  // Dev and Staging
  ReactGA.initialize("UA-100352253-6"); // Dev
  ReactGA.set({ anonymizeIp: true });
}

// This if clause is used to be able to design UI without Microsoft Word.
if (Office) {
  Office.initialize = () => {
    ReactDOM.render(
      <Fabric>
        <App />
      </Fabric>,
      document.getElementById("root")
    );
  };
} else {
  ReactDOM.render(
    <Fabric>
      <App />
    </Fabric>,
    document.getElementById("root")
  );
}

serviceWorker.unregister();
