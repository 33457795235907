import React from "react";

const ToggleBindingsItem = props => {
  return (
    <div
      className="list-group-item list-group-item-action flex-column align-items-start"
      onMouseEnter={() => props.markBinding(props.binding[0], true)}
      onMouseLeave={() => props.markBinding(props.binding[0], false)}
    >
      <div className="d-flex w-100">
        <button
          onClick={e => props.onRemoveBindingClick(props.binding[0])}
          type="button"
          className="close pull-right"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <span>{props.binding[1].text}</span>
      </div>
    </div>
  );
};

export default ToggleBindingsItem;
