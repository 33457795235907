import React, { Component } from "react";
import { wrapBodyInOoxml, getBodyFromOoxml } from "../utils/helpers";

import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";

/**
 * Modal to confirm removal of all bindings
 * @param {object} props
 */
const Modal = props => {
  return (
    <Dialog
      hidden={false}
      onDismiss={props.toggle}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Slutför dokument",
        subText:
          "Är du säker du vill slutföra dokumentet? Du kommer inte kunna anpassa innehållet med hjälp av DupliDocs (men kan fortfarande göra det manuellt). Den här åtgärden går ej ångra."
      }}
      modalProps={{
        titleAriaId: "myLabelId",
        subtitleAriaId: "mySubTextId",
        isBlocking: false,
        containerClassName: "ms-dialogMainOverride"
      }}
    >
      {null /** You can also include null values as the result of conditionals */}
      <DialogFooter>
        <PrimaryButton onClick={() => props.toggle(true)} text="Ja" />
        <DefaultButton onClick={() => props.toggle()} text="Nej" />
      </DialogFooter>
    </Dialog>
  );
};

/**
 * Action to get document output ready
 * This includes toggle for bindings highlights and option to remove all bindings
 */
export default class OutputActions extends Component {
  state = { modal: false };

  toggleModal = removeConfirm => {
    this.setState({ modal: !this.state.modal });
    if (removeConfirm === true) this.removeBindings();
  };

  /**
   * Helper method to execute a function in Word API and handle errors
   * @param {function} method function to execute in Word API
   */
  runInWord(method) {
    /* eslint-disable no-undef */
    Word.run(method.bind(this)).catch(error => {
      console.log("Error: " + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
    /* eslint-enable */
  }

  /**
   * Toggle background highlight in binding text
   * @param {boolean} showColor - Whether to show highlight color or not
   * @param {boolean} removeBinding - Whether to  remove binding as well or not
   */
  toggleHighlights = async (showColor, removeBinding) => {
    // console.info("DEBUG - OutputActions - toggleHighlights");
    this.props.toggleLoading();
    await this.setBindingsDeleteable(true);
    const { rules } = this.props;

    let colors = {};
    let outsideBindings = [];
    for (let ruleId in rules) {
      const rule = rules[ruleId];
      for (let bindingId in rule.bindings) {
        colors[bindingId] = rule.color;
        if (rule.bindings[bindingId].outsideBody === true) outsideBindings.push(bindingId);
      }
    }

    this.runInWord(context => {
      const body = context.document.body;
      const ooxml = body.getOoxml();
      return context.sync().then(() => {
        const contents = ooxml.value.toString();
        const parser = new DOMParser();
        let xmlDoc = parser.parseFromString(contents, "text/xml");
        const tags = xmlDoc.getElementsByTagName("w:tag");

        let bindings = {};
        for (let tag of tags) {
          const bindingId = tag.getAttribute("w:val");
          bindings[bindingId] = tag.parentNode.parentNode;
        }

        for (let bindingId in bindings) {
          if (outsideBindings.indexOf(bindingId) !== -1) continue;
          const color = showColor ? colors[bindingId] : "#FFFFFF";
          if (!color) continue;
          this.applyColor(xmlDoc, bindings[bindingId], color);
        }
        let serializer = new XMLSerializer();
        let newOoxml = serializer.serializeToString(xmlDoc);
        newOoxml = wrapBodyInOoxml(contents, getBodyFromOoxml(newOoxml));

        body.insertOoxml(newOoxml, "Replace");
        return context.sync().then(async () => {
          // process bindings outside body
          if (outsideBindings.length > 0) {
            await this.toggleOutsideHighlights(outsideBindings, colors, showColor);
          }
          //  Save highlight settings
          if (!removeBinding) {
            this.props.toggleLoading();
            this.props.onToggleHighlights(showColor);
            await this.setBindingsDeleteable(false);
          } else {
            this.deleteBindings();
            this.props.toggleLoading();
          }
        });
      });
    });
  };

  toggleOutsideHighlights = (bindings, colors, showColor) => {
    return new Promise(async resolve => {
      // enable editing
      await this.props.setDocumentEditable(true, true);
      // process bindings
      for (let bindingId of bindings) {
        // check for color
        const color = showColor ? colors[bindingId] : "#FFFFFF";
        if (!color) continue;
        // go ahead
        this.runInWord(context => {
          // get binding from document
          const docBindings = context.document.contentControls.getByTag(bindingId);
          docBindings.load("id");
          return context.sync().then(() => {
            // check & load ooxml
            if (!docBindings.items.length) return resolve();
            const ooxml = docBindings.items[0].getOoxml();
            return context.sync().then(async () => {
              const contents = ooxml.value;
              // process ooxml
              let xmlDoc = new DOMParser().parseFromString(contents, "text/xml");
              const tags = xmlDoc.getElementsByTagName("w:tag");
              // look for each binding in document
              for (let tag of tags) {
                const _bindingId = tag.getAttribute("w:val");
                if (bindingId !== _bindingId) continue;
                // binding matches
                const bindingNode = tag.parentNode.parentNode;
                await this.applyColor(xmlDoc, bindingNode, color);
                // form new ooxml
                let serializer = new XMLSerializer();
                let newOoxml = serializer.serializeToString(xmlDoc);
                newOoxml = wrapBodyInOoxml(contents, getBodyFromOoxml(newOoxml));
                // set new ooxml
                docBindings.items[0].insertOoxml(newOoxml, "Replace");
                return context.sync().then(resolve);
              }
            });
          });
        });
      }
    });
  };

  /**
   * Method to apply color to text in given element node
   * @param {object} xmlDoc - whole xml object for creating new nodes with
   * @param {object} elementNode - The xml object for node to apply color in
   * @param {string} color - the hex value of color to apply
   */
  applyColor = (xmlDoc, elementNode, color) => {
    let wr = elementNode.getElementsByTagName("w:r");
    Object.values(wr).forEach(row => {
      let stylingNodes = row.getElementsByTagName("w:rPr");
      let stylingNode;
      // Check if styling (w:rPr) exists for current node, if not create it
      if (!stylingNodes.length > 0) {
        stylingNode = xmlDoc.createElement("w:rPr");
        row.insertBefore(stylingNode, row.firstChild);
      } else {
        stylingNode = stylingNodes[0];
      }
      // Check if shading (w:shd) exists for current node, if not create it
      let shadingNodes = stylingNode.getElementsByTagName("w:shd");
      let shadingNode;
      if (!shadingNodes.length > 0) {
        shadingNode = xmlDoc.createElement("w:shd");
        stylingNode.appendChild(shadingNode);
      } else {
        shadingNode = shadingNodes[0];
      }
      shadingNode.setAttributeNS(
        "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
        "w:fill",
        color.replace("#", "").toUpperCase()
      );
      shadingNode.setAttributeNS(
        "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
        "w:val",
        "clear"
      );
      shadingNode.setAttributeNS(
        "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
        "w:color",
        "auto"
      );
    });
  };

  /**
   * Set bindings to be deleteable
   * If this is not done, changing highlight color for a bindings with subbindings fails
   */
  setBindingsDeleteable = deleteable => {
    return new Promise(resolve => {
      this.runInWord(context => {
        const bindings = context.document.contentControls;
        bindings.load("tag, id");
        return context.sync().then(() => {
          const totalBindings = bindings.items.length;
          if (!totalBindings === 0) return resolve();
          for (let i = 0; i < totalBindings; i++) {
            bindings.items[i].cannotDelete = !deleteable;
            bindings.items[i].cannotEdit = !deleteable;
          }
          return context.sync().then(resolve);
        });
      });
    });
  };

  /**
   * Remove all bindings and lock the document
   */
  removeBindings = () => {
    this.toggleHighlights(false, true);
    this.props.onRemoveBindings();
  };

  /**
   * Delete all bindings from document
   */
  deleteBindings = () => {
    this.runInWord(context => {
      const bindings = context.document.contentControls;
      bindings.load("tag, id");
      return context.sync().then(() => {
        const totalBindings = bindings.items.length;
        if (!totalBindings === 0) return;
        for (let i = 0; i < totalBindings; i++) {
          bindings.items[i].delete(true);
        }
        return context.sync().then(() => {});
      });
    });
  };

  render() {
    const { highlights } = this.props;
    return (
      <div>
        <div className="m-3">
          <span className="p-0 m-0  ms-fontWeight-semibold ms-font-m">
            Färgöverstrykning i dokument
          </span>
          <div className="d-flex">
            <div className="w-100">
              <div className="w-100 btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  className={
                    "w-100 btn btn-secondary" + (highlights ? " duplidoc-purple-light-bg" : "")
                  }
                  onClick={() => this.toggleHighlights(true)}
                >
                  <input type="radio" name="tog_hig" autoComplete="off" />
                  Visa
                </label>
                <label
                  className={
                    "w-100 btn btn-secondary" + (highlights ? "" : " duplidoc-purple-light-bg")
                  }
                  onClick={() => this.toggleHighlights(false)}
                >
                  <input type="radio" name="tog_hig" autoComplete="off" />
                  Dölj
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="m-3">
          <button
            type="button"
            onClick={this.toggleModal}
            className="w-100 btn btn-remove-bindings duplidoc-purple-bg btn-sm"
          >
            Slutför dokument
          </button>
        </div>
        {this.state.modal && <Modal toggle={this.toggleModal} />}
      </div>
    );
  }
}
