/**
 * Modal to confirm deletion of a toggle option
 */
import React from "react";
import { DefaultButton, ActionButton } from "office-ui-fabric-react/lib/Button";

const Modal = props => {
  return (
    <div className="modal fade in edit-modal" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h4>Bekräfta ta bort</h4>
            <div>Är du säker du vill ta bort alternativet från regeln?</div>
          </div>
          <div className="modal-footer">
            <ActionButton onClick={() => props.close(true)} text="Ta bort" />
            <DefaultButton onClick={() => props.close()} text="Avbryt" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
