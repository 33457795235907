/**
 * Component to reorder rules list
 */
import React, { Component } from "react";

export default class ChangeOrder extends Component {
  state = { rules: [] };

  componentDidMount() {
    this.setState({ rules: Object.keys(this.props.rules) });
  }

  /**
   * Move a rule up or down
   * @param {number} index - Index of rule to move
   * @param {boolean} next - move rule up if true, down if null
   */
  move(index, next) {
    let rules = this.state.rules.slice();
    const ruleId = rules[index];
    rules.splice(index, 1);
    index = next ? index - 1 : index + 1;
    rules.splice(index, 0, ruleId);
    this.setState({ rules: rules });
  }

  /**
   * Save new arrangement of rules
   */
  handleSave = () => {
    let rules = {};
    for (let i = 0; i < this.state.rules.length; i++) {
      const ruleId = this.state.rules[i];
      rules[ruleId] = this.props.rules[ruleId];
    }
    this.props.onSave(rules);
  };

  render() {
    const lastIndex = this.state.rules.length - 1;
    const rules = this.state.rules.map((ruleId, index) => (
      <div className="row" key={ruleId}>
        <div className="col">{this.props.rules[ruleId].name}</div>
        <div className="col">
          {index > 0 && (
            <button className="btn btn-xs" onClick={e => this.move(index, true)}>
              Upp
            </button>
          )}
          {index < lastIndex && (
            <button className="btn btn-xs" onClick={e => this.move(index)}>
              Ner
            </button>
          )}
        </div>
      </div>
    ));

    return (
      <div className="start">
        <div className="duplidoc-purple-bg p-1 d-flex align-items-center">
          <i
            onClick={this.props.goBack}
            style={{ fontSize: "20px" }}
            className="ms-Icon ms-Icon--ChromeBack text-white"
            aria-hidden="true"
          />
          <span className="w-100 p-0 m-0 text-uppercase ms-fontColor-white ms-font-xl text-center">
            Duplidocs
          </span>
          <i
            style={{ fontSize: "20px" }}
            className="ms-Icon ms-Icon--ChromeBack duplidoc-purple"
            aria-hidden="true"
          />
        </div>
        <div className="p-0 m-0 text-uppercase ms-fontColor-white duplidoc-purple-light-bg p ms-font-l text-center">
          Ändra ordning på regler
        </div>
        <div className="container-fluid mt-3">{rules}</div>
        <div className="m-3">
          <button
            onClick={this.handleSave}
            type="button"
            className="w-100 btn duplidoc-purple-bg text-white btn-sm"
          >
            Spara
          </button>
        </div>
      </div>
    );
  }
}
