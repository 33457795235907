/**
 * Get binding's sdt element from ooxml
 * @param {string} ooxml
 * @returns {string}
 */
export const getSdtFromOoxml = ooxml => {
  const startTag = "<w:sdt";
  const endTag = "</w:sdt>";
  const startIndex = ooxml.indexOf(startTag);
  const endIndex = ooxml.lastIndexOf(endTag) + endTag.length;
  return ooxml.substring(startIndex, endIndex);
};

/**
 * Get inner contents of a binding in w:sdtContent element
 * @param {string} ooxml - binding xml
 * @returns {object} XML DOM node
 */
export const getContentNode = ooxml => {
  ooxml = ooxml.trim();
  const startIndex = ooxml.indexOf("<w:sdt");
  let sdt;
  if (startIndex !== 0) {
    const endIndex = ooxml.lastIndexOf("</w:sdt>") + 8;
    sdt = ooxml.substring(startIndex, endIndex);
  } else {
    sdt = ooxml;
  }
  let full = `<w:body>${sdt}</w:body>`;
  full = wrapBodyInSmallOoxml(full);
  let parser = new DOMParser();
  let xmlDoc = parser.parseFromString(full, "text/xml");
  const contents = xmlDoc.getElementsByTagName("w:sdtContent")[0];
  return contents;
};

/**
 * Get only the w:body xml in a binding's OOXML
 * @param {string} ooxml - complete binding OOXML
 * @returns {string} just the w:body XML
 */
export const getBodyFromOoxml = (ooxml, bindingId) => {
  let startTag = "<w:body>";
  let endTag = "</w:body>";
  let startIndex = ooxml.indexOf(startTag);
  let endIndex = ooxml.lastIndexOf(endTag) + endTag.length;
  let body = ooxml.substring(startIndex, endIndex);
  if (!bindingId) return body;

  const firstIndex = body.indexOf("<w:tag");
  const lastIndex = body.lastIndexOf("<w:tag");
  if (firstIndex === lastIndex) return body;

  let xmlDoc = new DOMParser().parseFromString(ooxml, "text/xml");
  let tags = [];
  const allTags = xmlDoc.getElementsByTagName("w:tag");
  for (let i = 0; i < allTags.length; i++) {
    if (allTags[i].getAttribute("w:val") === bindingId) tags.push(allTags[i]);
  }
  const tagString = new XMLSerializer().serializeToString(
    tags[tags.length - 1].parentNode.parentNode
  );

  startTag = "<w:sdt";
  endTag = "</w:sdt>";
  startIndex = body.indexOf(startTag);
  endIndex = body.lastIndexOf(endTag) + endTag.length;
  body = body.substring(0, startIndex) + tagString + body.substring(endIndex);
  return body;
};

/**
 * Wrap OOXML containing only w:body xml in whole XML document
 * @param {string} ooxml - Compelte OOXML in the binding
 * @param {string} body - OOXMl's w:body contents
 * @returns {string} - body wrapped in ooxml
 */
export const wrapBodyInOoxml = (ooxml, body) => {
  const startTag = "<w:body>";
  const endTag = "</w:body>";
  if (body.indexOf(startTag) > 100) return body;

  const startIndex = ooxml.indexOf(startTag);
  const endIndex = ooxml.lastIndexOf(endTag) + endTag.length;

  ooxml = ooxml.substring(0, startIndex) + body + ooxml.substring(endIndex);
  return ooxml;
};

/**
 * Wrap w:body tag's OOXML in a XML package to make it a valid XML object
 * @param {string} body - OOXML of just w:body tag
 * @returns {string}
 */
export const wrapBodyInSmallOoxml = body => {
  return `
    <pkg:package xmlns:pkg='http://schemas.microsoft.com/office/2006/xmlPackage'>
      <pkg:part pkg:name='/_rels/.rels' pkg:contentType='application/vnd.openxmlformats-package.relationships+xml' pkg:padding='512'><pkg:xmlData><Relationships xmlns='http://schemas.openxmlformats.org/package/2006/relationships'><Relationship Id='rId1' Type='http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument' Target='word/document.xml'/></Relationships></pkg:xmlData></pkg:part>
      <pkg:part pkg:name='/word/document.xml' pkg:contentType='application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml'><pkg:xmlData><w:document xmlns:w='http://schemas.openxmlformats.org/wordprocessingml/2006/main'>${body}</w:document></pkg:xmlData></pkg:part>
    </pkg:package> `;
};

/**
 * Wrap text in small ooxml for parsing to XML Doc
 * @param {string} text
 * @returns {string}
 */
export const wrapTextInSmallOoxml = text => {
  return `<pkg:package xmlns:pkg='http://schemas.microsoft.com/office/2006/xmlPackage'>
    <pkg:part pkg:name='/_rels/.rels' pkg:contentType='application/vnd.openxmlformats-package.relationships+xml' pkg:padding='512'>
        <pkg:xmlData>
            <Relationships xmlns='http://schemas.openxmlformats.org/package/2006/relationships'>
                <Relationship Id='rId1' Type='http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument' Target='word/document.xml'/>
            </Relationships>
        </pkg:xmlData>
    </pkg:part>
    <pkg:part pkg:name='/word/document.xml' pkg:contentType='application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml'>
        <pkg:xmlData>
            <w:document xmlns:w='http://schemas.openxmlformats.org/wordprocessingml/2006/main' >
                <w:body>
                    <w:p>
                        <w:pPr>
                            <w:spacing w:before='360' w:after='0' w:line='480' w:lineRule='auto'/>
                            <w:rPr>
                                <w:color w:val='70AD47' w:themeColor='accent6'/>
                                <w:sz w:val='28'/>
                            </w:rPr>
                        </w:pPr>
                        <w:r>
                            <w:rPr>
                                <w:color w:val='70AD47' w:themeColor='accent6'/>
                                <w:sz w:val='28'/>
                            </w:rPr>
                            <w:t>${text}</w:t>
                        </w:r>
                    </w:p>
                </w:body>
            </w:document>
        </pkg:xmlData>
    </pkg:part>
  </pkg:package>`;
};
