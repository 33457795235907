import React from "react";
import RuleItem from "../components/RuleItem";

/**
 * Find the active toggle option
 * @param {object} toggles - Toggle options available
 * @returns {string} ID of active toggle option
 */
const findActiveToggle = toggles => {
  for (let i in toggles) {
    if (toggles[i].checked) return i;
  }
  return null;
};

/**
 * Map ruleID to active toggleID for that rule
 * @param {object} rules - User set rules on document
 * @returns {array} example array - {ToggleRule1: activeToggleFor1, ToggleRule2: activeToggleFor2}
 */
const processToggleDependencies = rules => {
  let data = {};
  for (let i in rules) {
    const rule = rules[i];
    if (rule.type !== "toggle") continue;
    data[i] = findActiveToggle(rule.toggles);
  }
  return data;
};

/**
 * Component to render list of available rules
 * @param {object} props - props passed to component
 */
const RuleList = props => {
  const rules = Object.entries(props.rules);
  const dependentRules = processToggleDependencies(props.rules);

  const RuleList = rules.map((rule, index) => {
    //  Hide the rule if it's dependent
    //  and the required toggle option is not met
    const dependent = rule[1].dependent;
    if (dependent && dependent.rule && !props.isEditing) {
      if (dependent.options.indexOf(dependentRules[dependent.rule]) === -1) return null;
    }

    return (
      <div key={index}>
        <RuleItem
          key={rule[0]}
          onToggleChange={toggleId => props.onToggleChange(rule[0], toggleId)}
          onRuleTextChange={(e, inputId) =>
            props.onRuleTextChange(rule[0], inputId, e.target.value)
          }
          setTextInBinding={props.setTextInBinding}
          onRuleRemoveClick={() => props.onRuleRemoveClick(rule[0])}
          onRuleEditClick={() => props.onRuleEditClick(rule[0])}
          toggleLoading={props.toggleLoading}
          setDocumentEditable={props.setDocumentEditable}
          rule={rule[1]}
          rules={props.rules}
          ruleId={rule[0]}
          color={rule[1].color}
          text={rule[1].text}
          name={rule[1].name}
          type={rule[1].type}
          toggles={rule[1].toggles}
          inputs={rule[1].inputs}
          isEditing={props.isEditing}
          updateRule={props.updateRule}
        />
        <hr />
      </div>
    );
  });
  return <div className="pl-3 pr-3 rule-list">{RuleList}</div>;
};

export default RuleList;
