import React, { Component } from "react";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";

/**
 * Modal to update name of toggle
 */
export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { text: props.toggle.name, error: null };
  }

  /**
   * Update text is state as user types in on input field
   * @param {object} event - onchange JS event
   */
  handleChange = event => {
    this.setState({ text: event.target.value });
  };

  /**
   * Handle click on save button
   * Validate value and save
   */
  handleSave = () => {
    let { text } = this.state;
    if (!text.replace(/ /g, "").length) {
      this.setState({ error: true });
    } else {
      this.props.close(this.state.text);
    }
  };

  /**
   * Component layout
   * @returns JSX
   */
  render() {
    return (
      <div className="modal fade in edit-modal" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>Edit name</h4>
              <input
                className="form-control"
                value={this.state.text}
                onChange={this.handleChange}
              />
              {this.state.error && <div class="text-danger">This field is required.</div>}
            </div>
            <div className="modal-footer">
              <PrimaryButton onClick={this.handleSave} text="Save" />
              <DefaultButton onClick={() => this.props.close()} text="Cancel" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
