import React, { Component } from "react";

/**
 * Component to show available toggle options for a selected rule
 */
export default class Toggles extends Component {
  /**
   * Componenet state
   * @param {array} options - Available toggle options for selected rule
   * @param {array} selected - IDs of checked/selected toggle options
   */
  state = { options: [], selected: [] };

  componentDidMount() {
    if (this.props.dependent.rule) this.loadOptions();
  }

  /**
   * Update available options when rule is changed
   * @param {object} nextProps - updated props passed to component
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.rule === this.props.rule) return;
    if (!nextProps.rule) {
      this.setState({ options: [], selected: [] });
    } else {
      this.loadOptions(nextProps.rule);
    }
  }

  /**
   * Make a list toggle options for a rule
   * @param {object} rule - selected toggle rule
   */
  loadOptions(rule) {
    if (!rule) rule = this.props.rule;
    const options = [];
    for (let i in rule.toggles) {
      options.push({ id: i, name: rule.toggles[i].name });
    }
    this.setState({ options: options, selected: this.props.dependent.options });
  }

  /**
   * Handle selected/deselect of a toggle option
   * @param {object} option - onclick event
   */
  handleClick(option) {
    let { selected } = this.state;
    const { id } = option;
    if (selected.indexOf(id) === -1) {
      selected.push(id);
    } else {
      selected.splice(selected.indexOf(id), 1);
    }
    this.setState({ selected: selected });
    this.props.update({ rule: this.props.rule.id, options: selected });
  }

  /**
   * Component layout
   * @returns {JSX}
   */
  render() {
    const { options, selected } = this.state;
    if (!options.length) return null;

    const page = options.map(option => (
      <div
        key={option.id}
        onClick={() => this.handleClick(option)}
        className="form-check dependent-option"
      >
        <input
          type="checkbox"
          onChange={() => {}}
          className="form-check-input"
          checked={selected.indexOf(option.id) !== -1}
        />{" "}
        {option.name}
      </div>
    ));

    return <div>{page}</div>;
  }
}
