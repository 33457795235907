import React, { Component } from "react";

import RuleSelector from "./RuleSelector";

export default class DependentRule extends Component {
  state = { showBtn: null, showRules: false, childRules: [] };

  /**
   * Decide if to show the depdent rule button or not
   * This button shall only show if there's one other rule than the current rule
   */
  componentDidMount() {
    const { id, rules } = this.props;
    let ruleIds = Object.keys(rules);
    let show;
    if (ruleIds.length > 1) {
      //  there are more than one rules
      show = true;
    } else if (ruleIds.indexOf(id) === -1 && ruleIds.length > 0) {
      //  there's only one RULE, but we are creating the second one
      show = true;
    } else {
      //  No other rule than this one
      show = false;
    }
    this.setState({ showBtn: show, showRules: !!this.props.dependent.rule }, this.checkDependency);
  }

  /**
   * Find rules that are dependent on current rule being edited
   */
  checkDependency = () => {
    const { rules, id } = this.props;
    //  no child rules if creating the rule or there are not rules
    if (!id || Object.keys(rules).length) return;
    //  find dependent rules
    let dependentRules = [];
    for (let i in rules) {
      if (rules[i].dependent.rule === id) dependentRules.push(rules[i].name);
    }
    //  update state
    this.setState({ childRules: dependentRules });
  };

  /**
   * Handle click on "Make rule dependent" button
   * This shall show or hide the rule selection
   */
  handleClick = () => {
    this.setState({ showRules: !this.state.showRules });
    this.props.update({ rule: null, options: [] });
  };

  /**
   * Component layout
   * @returns {JSX}
   */
  render() {
    const { showBtn, showRules, childRules } = this.state;
    if (!showBtn) return null;

    if (childRules.length) {
      return (
        <div className="sub-rules">
          <div className="title">Dependent rules</div>
          <ul>
            {childRules.map((rule, index) => (
              <li key={index}>{rule}</li>
            ))}
          </ul>
        </div>
      );
    }

    let button;
    if (!showRules) {
      button = (
        <button className="w-100 btn btn-sm btn-dependent enable" onClick={this.handleClick}>
          Gör beroende av annan regel
        </button>
      );
    } else {
      button = (
        <button
          className="w-100 btn btn-sm btn-dependent duplidoc-purple-bg"
          onClick={this.handleClick}
        >
          Ta bort beroende
        </button>
      );
    }

    return (
      <div>
        {/* Button to show or hide rule selection */}
        {button}

        {/* Rule selection */}
        {showRules && <RuleSelector {...this.props} />}
      </div>
    );
  }
}
