import React, { Component } from "react";

import shortid from "shortid";

import TextBindingList from "../components/TextBindingList";
import ToggleBindingsList from "../components/ToggleBindingsList";
import DependentRule from "./DependentRule/Index";
import {
  getSdtFromOoxml,
  getBodyFromOoxml,
  getContentNode,
  wrapBodyInSmallOoxml
} from "../utils/helpers";

/**
 * Color counter
 * This counter is used to cycle through colors list in ascending order
 * and not select a random color
 */
const colors = [
  "#CCFFB2",
  "#FEFF9F",
  "#ABEAF9",
  "#F5C1FF",
  "#FEA7DA",
  "#FEDEDF",
  "#E0E1FF",
  "#FFF4E0",
  "#DDFFE6",
  "#FFB9BB",
  "#FFCCBB",
  "#FEE0BA",
  "#FFEEB8",
  "#DFB3E4",
  "#CAA7DD",
  "#AC9BD1"
];

/**
 * Main component for creating a new rule
 */
class NewRuleForm extends Component {
  constructor(props) {
    super(props);
    this.ignoreNextBinding = false;
    this.onBindingDataChangedListeners = [];
    this.createBindings = false;
    if (props.rule) {
      this.state = props.rule;
    } else {
      let toggleId = shortid.generate();
      let defaultInput = shortid.generate();
      this.state = {
        name: "",
        helperText: null,
        type: null,
        dependent: {
          rule: null,
          options: []
        },
        bindings: {},
        parentBindings: {},
        toggles: {
          [toggleId]: {
            name: "Alternativ 1",
            text: "",
            ooxml: {},
            bindings: [],
            checked: true
          }
        },
        inputs: {
          [defaultInput]: {
            text: "",
            height: "30px",
            bindings: []
          }
        },
        selectedInput: defaultInput,
        text: "",
        color: colors[props.colorCounter],
        selectedToggle: toggleId,
        msg: 0
      };
    }
  }

  addNewSuffix = () => {
    // console.log(this.state);
    let inputId = shortid.generate();
    let inputs = Object.assign({}, this.state.inputs);
    inputs[inputId] = {
      text: "",
      bindings: []
    };
    this.setState({ inputs, selectedInput: inputId });
  };

  /**
   * Method to change to a different toggle option
   * @param {string} toggleId - Id of the toggle to change to
   */
  async onToggleChange(toggleId) {
    // console.info("toggle changed", toggleId);
    this.props.setSpinner(true);
    let { selectedToggle, bindings, toggles } = this.state;
    await this.props.setDocumentEditable(true);

    this.runInWord(context => {
      const body = context.document.body;
      const ooxml = body.getOoxml();
      return context.sync().then(() => {
        let contents = ooxml.value.toString();

        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(contents, "text/xml");
        let serializer = new XMLSerializer();

        let tags = xmlDoc.getElementsByTagName("w:tag");
        let allBindings = {};
        for (let tag of tags) {
          const bindingId = tag.getAttribute("w:val");
          allBindings[bindingId] = tag.parentNode.parentNode;
        }

        const bindingIds = Object.keys(bindings);
        //  process each binding
        for (let bindingId of bindingIds) {
          const binding = allBindings[bindingId];
          if (!binding) continue;
          bindings[bindingId].ooxml[selectedToggle] = this.props.getOuterHTML(binding);

          const newStr = bindings[bindingId].ooxml[toggleId];
          const newObj = getContentNode(newStr);
          const currentObj = binding.getElementsByTagName("w:sdtContent")[0];
          binding.replaceChild(newObj, currentObj);
        }

        let newOoxml = serializer.serializeToString(xmlDoc);
        newOoxml = wrapBodyInSmallOoxml(getBodyFromOoxml(newOoxml));
        body.insertOoxml(newOoxml, "Replace");
        return context.sync().then(async () => {
          //  update state
          toggles[selectedToggle].checked = false;
          toggles[toggleId].checked = true;
          this.setState({ selectedToggle: toggleId, toggles: toggles, bindings: bindings });
          await this.props.setDocumentEditable(false, true);
          this.props.setSpinner(false);
        });
      });
    });
  }

  /**
   * Logic to remove a bindings from state
   * @param {string} bindingId - The unique ID of binding to remove
   */
  removeBindingFromState = bindingId => {
    //  remove bindings from bindings object
    let bindings = Object.assign({}, this.state.bindings);
    delete bindings[bindingId];

    //  remove bindings from inputs object
    let inputs = Object.assign({}, this.state.inputs);
    let inputId = null;
    for (let i in inputs) {
      let _bindings = inputs[i].bindings;
      const index = _bindings.indexOf(bindingId);
      if (index === -1) continue;
      _bindings.splice(index, 1);
      if (_bindings.length === 0) inputId = i;
    }

    // remove ending if all bindings in it are deleted
    if (this.state.type === "text" && Object.keys(inputs).length > 1 && inputId !== null) {
      delete inputs[inputId];
      const firstInput = Object.keys(inputs)[0];
      this.setState({ selectedInput: firstInput });
    }

    //  update state with new values
    this.setState({ bindings, inputs });
  };

  handleRemoveBindingClick = async bindingId => {
    // console.log(`DEBUG: NewRuleForm - handleRemoveBindingClick`);
    await this.props.removeBindingFromWord(bindingId);
    this.removeBindingFromState(bindingId);
    this.props.setDocumentEditable(false, true);
  };

  componentWillUnmount() {
    // console.log(`DEBUG: NewRuleForm - componentWillUnmount`);
    this.disableBindingCreation();
  }

  // enable or disable binding creation
  toggleBindingCreation = () => {
    this.createBindings ? this.disableBindingCreation() : this.enableBindingCreation();
    this.createBindings = !this.createBindings;
    this.setState({});
  };

  // Listen to selection
  enableBindingCreation = () => {
    /* eslint-disable */
    Office.context.document.addHandlerAsync(
      "documentSelectionChanged",
      this.triggerSelectTextToBind
    );
    /* eslint-enable */
  };

  // Stop listening to selection
  disableBindingCreation = () => {
    /* eslint-disable */
    Office.context.document.removeHandlerAsync("documentSelectionChanged", {
      handler: this.triggerSelectTextToBind
    });
    /* eslint-enable */
  };

  onSaveClick = () => {
    // console.log(`DEBUG: NewRuleForm - onSaveClick`);
    let newCounter = this.props.colorCounter + 1;
    if (newCounter >= colors.length) newCounter = 0;
    this.props.setColorCounter(newCounter);
    if (this.state.type === "toggle") {
      this.saveToggleRule();
    } else {
      this.props.onRuleSaveClick(this.state);
    }
  };

  /**
   * Logic to execute when save button is clicked for toggle rules
   * This function reads current content in all bindings, save then and go back to main page
   */
  async saveToggleRule() {
    this.props.setSpinner(true);
    let { bindings, selectedToggle } = this.state;
    for (let bindingId of Object.keys(bindings)) {
      let ooxml = await this.props.getOoxmlInBinding(bindingId);
      if (ooxml) bindings[bindingId].ooxml[selectedToggle] = getSdtFromOoxml(ooxml);
    }
    this.setState({ bindings: bindings }, () => {
      this.props.setSpinner(false);
      this.props.onRuleSaveClick(this.state);
    });
  }

  handleToggleNameChange = (toggleId, value) => {
    // console.log(`DEBUG: NewRuleForm - handleToggleNameChange`);
    let toggles = Object.assign({}, this.state.toggles);
    toggles[toggleId].name = value;
    this.setState({ toggles });
  };

  handleNameChange = e => {
    // console.log(`DEBUG: NewRuleForm - handleNameChange`);
    this.setState({ name: e.target.value });
  };

  handleDefaultTextChange = value => {
    // console.log(`DEBUG: NewRuleForm - handleDefaultTextChange`);
    this.setState({ text: value });
  };

  /**
   * Method to add a new toggle option
   * This method should:
   *  - read the OOXML in binding for changes and save it to DB
   *  - add new toggle option with same ooxml value
   */
  async addToggle() {
    let { bindings, selectedToggle, toggles } = this.state;

    //  add new toggle option
    const newToggleId = shortid.generate();
    toggles[newToggleId] = {
      name: `Alternativ ${Object.keys(toggles).length + 1}`,
      checked: true
    };
    toggles[selectedToggle].checked = false;

    //  process bindings
    for (let bindingId of Object.keys(bindings)) {
      //  read data in each binding
      let ooxml = await this.props.getOoxmlInBinding(bindingId);
      //  set current and new data
      if (ooxml) {
        const body = getSdtFromOoxml(ooxml);
        bindings[bindingId].ooxml[selectedToggle] = body;
        bindings[bindingId].ooxml[newToggleId] = body;
      }
    }
    //  update store if it's the last binding
    this.setState({ bindings: bindings, toggles: toggles, selectedToggle: newToggleId });
  }

  /**
   * Delete a toggle option
   * @param {string} toggleId - ID of toggle option to delete
   */
  handleToggleDelete = toggleId => {
    let toggles = Object.assign({}, this.state.toggles);
    if (toggles[toggleId].checked) return console.warn("Active toggle cannot be deleted");
    delete toggles[toggleId];
    this.setState({ toggles });
  };

  triggerSelectTextToBind = selection => {
    console.log("DEBUG: NewRuleForm - triggerSelectTextToBind at", new Date().getTime());
    if (!selection || !selection.document || !this.state.type) return;
    if (this.ignoreNextBinding) {
      this.ignoreNextBinding = false;
      return;
    }
    // this.setState({ msg: "not ignoring" });
    /* eslint-disable */
    selection.document.getSelectedDataAsync(Office.CoercionType.Text, selectedDataResult => {
      // this.setState({ msg: "got selection" });
      let text = selectedDataResult.value;
      if (text === "") return;
      this.ignoreNextBinding = true;
      this.newBindingFromSelection(text);
    });
    /* eslint-enable */
  };

  /**
   * Helper method to execute a function in Word API and handle errors
   * @param {function} method function to execute in Word API
   */
  runInWord(method) {
    /* eslint-disable no-undef */
    Word.run(method.bind(this)).catch(error => {
      console.log("Error: " + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
    /* eslint-enable */
  }

  /**
   * Function to create a new binding from selection
   * This method wraps the selection in content control and applied a highlight color
   *
   * Note: The code `this.setState({ msg: this.state.msg + 1 })` is not random and serves a purpose
   * It helps remove the slowdown or freeze bug when creating a new rule by re-rendering the plugin
   * Removing any of the line in the function below will result in unexpected behaviour
   *
   */
  newBindingFromSelection = async text => {
    this.setState({ msg: this.state.msg + 1 });
    this.runInWord(async context => {
      const selection = context.document.getSelection();
      const binding = selection.insertContentControl();
      binding.placeholderText = "";
      const bindingId = "binding#" + shortid.generate();
      binding.tag = bindingId;
      binding.cannotDelete = true;
      return context.sync().then(
        async function() {
          setTimeout(() => {
            this.setState({ msg: this.state.msg + 1 });
          }, 500);
          setTimeout(() => {
            this.setState({ msg: this.state.msg + 1 });
          }, 1000);
          setTimeout(() => {
            this.setState({ msg: this.state.msg + 1 });
          }, 2000);
          await this.props.applyHighlightToBinding(bindingId, this.state.color);
          this.addBindingToState(bindingId, text);
          this.setState({ msg: this.state.msg + 1 });
        }.bind(this)
      );
    });
  };

  /**
   * Check if a binding is in body or in header/footer
   * @param {string} bindingId - The tag value for binding
   * @returns {Promise} `true` if binding is normal, `false` if it's outside body
   */
  inspectBinding = bindingId => {
    return new Promise(resolve => {
      this.runInWord(context => {
        const docBody = context.document.body;
        const ooxml = docBody.getOoxml();
        return context.sync().then(() => {
          let contents = ooxml.value.toString();
          const bodyStartIndex = contents.indexOf("<w:body>");
          const bodyEndIndex = contents.indexOf("</w:body>");
          const bindingTag = `<w:tag w:val="${bindingId}"`;
          const bindingIndex = contents.indexOf(bindingTag);
          if (bindingIndex === -1) {
            // binding not found in document
            resolve(true);
          } else if (bindingIndex > bodyStartIndex && bindingIndex < bodyEndIndex) {
            // binding found at normal position
            resolve(true);
          } else {
            // binding at abnormal position
            resolve(false);
          }
        });
      });
    });
  };

  /**
   * Add the binding to react store
   * @param {string} bindingId - Tag name of the newly created binding
   * @param {string} text - Text within binding
   */
  addBindingToState = async (bindingId, text) => {
    console.info("created binding", bindingId);

    let bindings = Object.assign({}, this.state.bindings);
    bindings[bindingId] = { text: text };

    //  For freetext rules, simply save text
    if (this.state.type !== "toggle") {
      const isInsideBody = await this.inspectBinding(bindingId);
      if (!isInsideBody) bindings[bindingId].outsideBody = true;
      let inputs = Object.assign({}, this.state.inputs);
      inputs[this.state.selectedInput].bindings.push(bindingId);
      inputs[this.state.selectedInput].text = text.trim();
      return this.setState({ bindings: bindings });
    }

    //  For toggle rules, need to save OOXML
    this.runInWord(context => {
      const docBindings = context.document.contentControls.getByTag(bindingId);
      context.load(docBindings, "text");
      return context.sync().then(
        function() {
          if (docBindings.items.length === 0) {
            return console.log("Binding not found in Document");
          }
          const binding = docBindings.items[0];
          var ooxml = binding.getOoxml();
          return context.sync().then(
            function() {
              let toggles = Object.assign({}, this.state.toggles);
              let toggleText = {};
              const body = getSdtFromOoxml(ooxml.value);
              Object.keys(toggles).forEach(toggle => {
                toggleText[toggle] = body;
              });

              let label;
              const MAX_LENGTH = 28;
              if (text.length > MAX_LENGTH) {
                label = text.substring(0, MAX_LENGTH - 3) + "...";
              } else {
                label = text;
              }

              bindings[bindingId].text = label;
              bindings[bindingId].ooxml = toggleText;
              this.setState({ bindings: bindings });
            }.bind(this)
          );
        }.bind(this)
      );
    });
  };

  setType = type => {
    // console.log(`DEBUG: NewRuleForm - setType`);
    if (Object.keys(this.state.bindings).length) return;
    this.setState({ type });
  };

  /**
   * Apply and remove temporary highlight from a binding
   * This highlight is applied on hover over binding in Duplidocs
   * @param {string} bindingId - Tag ID of binding
   * @param {bool} highlight - whether to apply highlight or remove it
   */
  markBinding = async (bindingId, highlight) => {
    const color = highlight ? "#FF0000" : this.state.color.toUpperCase();
    this.props.applyHighlightToBinding(bindingId, color);
  };

  render() {
    // console.info(`DEBUG: NewRuleForm - render`);
    return (
      <div className="start">
        <div className="duplidoc-purple-bg p-1 d-flex align-items-center">
          <i
            onClick={() => this.props.onBackButtonClick(this.state)}
            style={{ fontSize: "20px" }}
            className="ms-Icon ms-Icon--ChromeBack text-white"
            aria-hidden="true"
          />
          <span className="w-100 p-0 m-0 text-uppercase ms-fontColor-white ms-font-xl text-center">
            Duplidocs
          </span>
          <i
            style={{ fontSize: "20px" }}
            className="ms-Icon ms-Icon--ChromeBack duplidoc-purple"
            aria-hidden="true"
          />
        </div>
        <div className="p-0 m-0 text-uppercase ms-fontColor-white duplidoc-purple-light-bg p ms-font-l text-center">
          Ny regel<span style={{ visibility: "hidden" }}>{this.state.msg}</span>
        </div>
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col">
              <span className="p-0 m-0  ms-fontWeight-semibold ms-font-m">
                Vad behandlar regeln?
              </span>
              <div className="input-group mb-3">
                <input
                  onChange={this.handleNameChange.bind(this)}
                  value={this.state.name}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder='T.ex. "Finns koncernredovisning?"'
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <span className="p-0 m-0  ms-fontWeight-semibold ms-font-m">
                Hjälptext (om behövs)
              </span>
              <div className="input-group mb-3">
                <input
                  value={this.state.helperText || ""}
                  onChange={({ target: { value } }) => this.setState({ helperText: value })}
                  className="form-control"
                  aria-describedby="formatHelp"
                  placeholder='T.ex. "format dd-mm-yyyy"'
                />
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col">
              <span className="p-0 m-0  ms-fontWeight-semibold ms-font-m">
                Hur ska texten anpassas?
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
                <label
                  onClick={() => {
                    this.setType("text");
                  }}
                  className={`btn btn-secondary w-100 ${
                    this.state.type === "text" ? "duplidoc-purple-light-bg" : ""
                  }`}
                  style={{ marginRight: "1px" }}
                >
                  <input type="radio" name="text" autoComplete="off" />
                  Fritext
                </label>

                <label
                  style={{ marginLeft: "1px" }}
                  onClick={() => {
                    this.setType("toggle");
                  }}
                  className={`btn btn-secondary w-100 ${
                    this.state.type === "toggle" ? "duplidoc-purple-light-bg" : ""
                  }`}
                >
                  <input type="radio" name="toggle" autoComplete="off" />
                  Knappval
                </label>
              </div>
            </div>
          </div>
          {this.state.type !== null && (
            <div>
              <hr />
              <span className="p-0 m-0  ms-fontWeight-semibold ms-font-m">
                Vilken text skall anpassas?
              </span>

              <button
                onClick={this.toggleBindingCreation}
                type="button"
                className={`w-100 btn duplidoc-purple${
                  this.createBindings ? "-light" : ""
                }-bg text-white toggle-selection-btn btn-sm`}
              >
                {this.createBindings ? "Sluta markera" : "Markera text"}
              </button>

              <div>
                {Object.values(this.state.bindings).length < 1 && (
                  <span className="ms-font-m">
                    Markera de ord och meningar som regeln ska ändra.
                  </span>
                )}
                {Object.values(this.state.bindings).length > 0 && (
                  <div>
                    <div className="ms-font-m mb-3">
                      Lägg till fler ord och meningar som ska ändras.
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.type === "toggle" && (
            <div>
              <ToggleBindingsList
                handleToggleNameChange={this.handleToggleNameChange}
                markBinding={this.markBinding}
                handleToggleDelete={this.handleToggleDelete}
                selectedToggle={this.state.selectedToggle}
                onToggleChange={toggleId => this.onToggleChange(toggleId)}
                onRemoveBindingClick={this.handleRemoveBindingClick}
                toggles={this.state.toggles}
                bindings={this.state.bindings}
              />
              <button
                onClick={() => this.addToggle()}
                type="button"
                className="w-100 btn duplidoc-purple-bg text-white btn-sm"
              >
                Lägg till knappval
              </button>
              <span className="ms-font-m">Lägg till alternativ användaren kan växla mellan.</span>
            </div>
          )}
          {this.state.type === "text" && (
            <div>
              <div className="row">
                <div className="col">
                  {Object.values(this.state.bindings).length > 0 && (
                    <div>
                      <TextBindingList
                        onRemoveBindingClick={this.handleRemoveBindingClick}
                        markBinding={this.markBinding}
                        bindings={this.state.bindings}
                        inputs={this.state.inputs}
                        selectedInput={this.state.selectedInput}
                        selectInput={id => this.setState({ selectedInput: id })}
                      />
                    </div>
                  )}
                  <button
                    onClick={this.addNewSuffix}
                    type="button"
                    className="w-100 mt-3 btn duplidoc-purple-bg text-white btn-sm"
                  >
                    Lägg till ändelse
                  </button>
                </div>
              </div>
            </div>
          )}
          <DependentRule
            {...this.props}
            dependent={this.state.dependent}
            update={data => this.setState({ dependent: data })}
          />
          <div className="m-3">
            <button
              onClick={this.onSaveClick}
              type="button"
              className="w-100 btn duplidoc-purple-bg text-white btn-sm"
            >
              Spara
            </button>
            <button
              onClick={() => this.props.onBackButtonClick(this.state)}
              style={{ marginTop: "10px" }}
              type="button"
              className="w-100 btn btn-default btn-sm"
            >
              Avbryt
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default NewRuleForm;
