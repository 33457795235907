/* global Office */
import "babel-polyfill";
import React, { Component } from "react";

import { initializeIcons } from "@uifabric/icons";
import shortid from "shortid";
import ReactGA from "react-ga";
import JSZip from "jszip";

// import Start from "./components/Start";
import ChangeOrder from "./components/ChangeOrder";
import Template from "./components/ViewTemplate";
import NewRuleForm from "./components/NewRuleForm";
import Spinner from "./components/Spinner";

import { saveSettings } from "./utils/office";
import {
  getBodyFromOoxml,
  wrapBodyInOoxml,
  getContentNode,
  wrapBodyInSmallOoxml,
  wrapTextInSmallOoxml,
} from "./utils/helpers";

import "./App.css";

initializeIcons(/* optional base url */);
class App extends Component {
  constructor(props) {
    super(props);
    this.typingTimeout = {};
    this.state = {
      isEditing: false,
      editingRuleId: null,
      editingRule: null,
      updating: false,
      rules: {},
      view: "Template",
      showHighlights: true,
      colorCounter: null,
      progress: null,
    };
    // Extended logging for MS Office
    /* eslint-disable no-undef */
    // OfficeExtension.config.extendedErrorLogging = true;
    /* eslint-enable */
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(`DEBUG: App - componentDidMount`);
    /* eslint-disable no-undef */
    const { view, rules, isEditing } = this.state;
    if (prevState.view !== view) {
      // Send a page view to Google Analytics if the current view has changed
      ReactGA.pageview("/" + view);

      Office.context.document.settings.set("view", view);
      saveSettings(rules);
      /* eslint-enable no-undef */
    }
    if (prevState.rules !== rules) {
      saveSettings(rules);
    }

    // Enable/disable binding editing on toggling edit mode
    if (prevState.isEditing !== isEditing) this.setDocumentEditable(isEditing);
  }

  componentDidMount() {
    // Send a page view to Google Analytics based on the current view
    let view = Office.context.document.settings.get("view");
    view = view ? view : "Template";
    ReactGA.pageview("/" + view);
  }

  componentWillMount() {
    // console.log(`DEBUG: App - componentWillMount`);
    /* eslint-disable */
    const counter = Office.context.document.settings.get("colorCounter");
    let view = Office.context.document.settings.get("view");
    let highlights = Office.context.document.settings.get("highlights");
    /* eslint-enable */
    if (highlights !== false) highlights = true;
    this.setState({
      view: view ? view : "Template",
      showHighlights: highlights,
      colorCounter: counter || 0,
    });
    // });
    /* eslint-enable */

    this.getRulesFromWordSettings();
  }

  /**
   * Save color counter value in state and Word Settings
   * @param {number} num - new value of color counter
   */
  setColorCounter = (num) => {
    this.setState({ colorCounter: num });
    /* eslint-disable */
    Office.context.document.settings.set("colorCounter", num);
    Office.context.document.settings.saveAsync();
    /* eslint-enable */
  };

  /**
   * Function to show and hide loading indicator
   */
  toggleLoading = () => this.setState({ updating: !this.state.updating, progress: null });

  /**
   * Helper method to execute a function in Word API and handle errors
   * @param {function} method function to execute in Word API
   */
  runInWord(method) {
    /* eslint-disable no-undef */
    Word.run(method.bind(this)).catch((error) => {
      console.log("Error: " + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
    /* eslint-enable */
  }

  onNewRuleClick = () => {
    // console.log(`DEBUG: App - onRuleRemoveClick`);
    saveSettings(this.state.rules);
    this.setState({
      view: "NewRuleForm",
      editingRuleId: null,
      editingRule: null,
    });
  };

  handleChangeView = (view) => {
    // console.log(`DEBUG: App - handleChangeView`);
    this.setState({ view: view });
  };

  getRulesFromWordSettings = () => {
    // console.log(`DEBUG: App - getRulesFromWordSettings`);
    /* eslint-disable no-undef */
    let documentRules = Office.context.document.settings.get("rules");
    this.setState({ rules: documentRules ? documentRules : {} });
    /* eslint-enable no-undef */
  };

  /**
   * Set bindings to be editable or not
   * toggles cannotEdit property on bindings
   * @param {bool} canEdit
   */
  setDocumentEditable = (canEdit, editOverride) => {
    return new Promise((resolve) => {
      this.runInWord((context) => {
        const bindings = context.document.contentControls;
        bindings.load("tag, id");
        return context.sync().then(() => {
          const totalBindings = bindings.items.length;
          for (let i = 0; i < totalBindings; i++) {
            if (editOverride === null || editOverride === undefined) {
              bindings.items[i].cannotEdit = !canEdit;
            } else {
              bindings.items[i].cannotEdit = !editOverride;
            }
            bindings.items[i].cannotDelete = !canEdit;
          }
          return context.sync().then(resolve);
        });
      });
    });
  };

  /**
   * Get ID of sub-bindings in a binding object
   * @param {object} obj - binding's XML object
   * @param {string} currentBindingId - parent binding ID, which shall be ignored if found in XMLobj
   * @returns {array} array of subbindings found in object
   */
  getSubBindingIdsFromObject = (obj, currentBindingId) => {
    const tags = obj.getElementsByTagName("w:tag");
    let bindingIds = [];
    for (let tag of tags) {
      const bindingId = tag.getAttribute("w:val");
      if (bindingId !== currentBindingId) bindingIds.push(bindingId);
    }
    return bindingIds;
  };

  /**
   * Logic to update sub-bindings to latest value
   * @param {string} bindingId - ID of sub-binding to update
   * @param {object} obj - XML object of parent binding
   * @returns {string} For toggle sub-bindings, latest ooxml value of subbinding
   * @return {null} For text sub-bindings, as they are updated directly in this method
   */
  processSubBinding = (bindingId, obj) => {
    const { rules } = this.state;
    for (let ruleId in rules) {
      const rule = rules[ruleId];
      if (bindingId in rule.bindings) {
        // for toggle
        if (rule.type === "toggle") {
          const ooxml = rule.bindings[bindingId].ooxml[rule.selectedToggle];
          return ooxml;
        }
        // for text rule
        const tags = obj.getElementsByTagName("w:tag");
        for (let tag of tags) {
          if (tag.getAttribute("w:val") === bindingId) {
            let text = " ";
            for (let inputId in rule.inputs) {
              if (rule.inputs[inputId].bindings.indexOf(bindingId) !== -1) {
                text = rule.inputs[inputId].text;
                break;
              }
            }
            const textOoxml = wrapTextInSmallOoxml(text);
            const textDoc = new DOMParser().parseFromString(textOoxml, "text/xml");
            const sourceElm = textDoc.getElementsByTagName("w:t")[0];
            const targetObj = tag.parentNode.parentNode.getElementsByTagName("w:t")[0];
            const parent = targetObj.parentNode;
            parent.replaceChild(sourceElm, targetObj);
            return;
          }
        }
      }
    }
  };

  /**
   * Logic to execute when user select a different toggle option
   *
   * The method shall read the OOXML in the binding and save it in store before updating the binding
   * to preserve nested rules. With bindings set to be non-deleteable, a sub-binding must be set to
   * deleteable, so that it can be removed from the page.
   *
   * @param {string} ruleId
   * @param {string} toggleId
   *
   */
  onToggleChange = async (ruleId, toggleId) => {
    console.info("DEBUG: App - onToggleChange", toggleId);
    this.setState({ updating: true });
    await this.setDocumentEditable(true);
    const start = new Date();

    this.runInWord((context) => {
      const body = context.document.body;
      const ooxml = body.getOoxml();
      return context.sync().then(() => {
        let contents = ooxml.value.toString();

        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(contents, "text/xml");
        let serializer = new XMLSerializer();

        let tags = xmlDoc.getElementsByTagName("w:tag");
        let allBindings = {};
        for (let tag of tags) {
          const bindingId = tag.getAttribute("w:val");
          allBindings[bindingId] = tag.parentNode.parentNode;
        }

        let rules = Object.assign({}, this.state.rules);
        let rule = rules[ruleId];
        const bindingIds = Object.keys(rule.bindings);

        // process each binding
        for (let bindingId of bindingIds) {
          const binding = allBindings[bindingId];
          if (!binding) continue;
          // save contents of current toggle to store
          rule.bindings[bindingId].ooxml[rule.selectedToggle] = this.getOuterHTML(binding);
          // load contents of new toggle from store
          const newStr = rule.bindings[bindingId].ooxml[toggleId];
          const newObj = getContentNode(newStr);
          const currentObj = binding.getElementsByTagName("w:sdtContent")[0];
          // replace binding data
          binding.replaceChild(newObj, currentObj);
          // subbindings
          // todo: this logic might need refactoring or simplification
          const subbindingIds = this.getSubBindingIdsFromObject(newObj, bindingId);
          for (let subbindingId of subbindingIds) {
            const subOoxml = this.processSubBinding(subbindingId, newObj);
            if (!subOoxml) continue;
            // continue ahead for toggle rules
            const newSubContents =
              typeof subOoxml === "string" ? getContentNode(subOoxml) : subOoxml;
            const subBindingObjects = binding.getElementsByTagName("w:tag");
            for (let subBindingObject of subBindingObjects) {
              if (subBindingObject.getAttribute("w:val") === subbindingId) {
                const subBindingParent = subBindingObject.parentNode.parentNode;
                const oldSubContents = subBindingParent.getElementsByTagName("w:sdtContent")[0];
                subBindingParent.replaceChild(newSubContents, oldSubContents);
                continue;
              }
            }
          }
        }

        let newOoxml = serializer.serializeToString(xmlDoc);

        // Logic to remove w:showingPlcHdr tag from body
        // this tag results in bug - https://trello.com/c/xUbcXWH7/216-cant-create-nested-rule & https://trello.com/c/xRqpXfRs/248-not-possible-to-add-freetext-field-to-toggle-field-in-new-documents
        // TODO: Improve performance by doing a single regexp replacement
        let oBody = getBodyFromOoxml(newOoxml);
        const plcTag = "<w:showingPlcHdr />";
        const plcTag2 = "<w:showingPlcHdr/>";
        while (oBody.indexOf(plcTag) !== -1) oBody = oBody.replace(plcTag, "");
        while (oBody.indexOf(plcTag2) !== -1) oBody = oBody.replace(plcTag2, "");;

        newOoxml = wrapBodyInOoxml(contents, oBody);
        while (newOoxml.indexOf(`<w:lock w:val="sdtLocked" />`) !== -1) {
          newOoxml = newOoxml.replace(`<w:lock w:val="sdtLocked" />`, "");
        }
        body.insertOoxml(newOoxml, "Replace");
        return context.sync().then(() => {
          // Change checked toggle to current toggle
          rule.toggles[rule.selectedToggle].checked = false;
          rule.toggles[toggleId].checked = true;
          rule.selectedToggle = toggleId;
          // Update rule in state
          rules[ruleId] = rule;
          this.setState({ rules: rules }, async () => {
            if (!this.state.isEditing) await this.setDocumentEditable(false);
            this.setState({ updating: false });
            const diff = new Date() - start;
            console.log(`took ${diff / 1000} seconds`);
          });
        });
      });
    });

    /**
     * Same code to stop execution here
     * The code below is preserved for benchmarking
     */
    let x = 2;
    if (x === 2) return;

    // const start = new Date();
    this.setState({ updating: true });
    if (!this.state.isEditing) await this.setDocumentEditable(true);
    let rules = Object.assign({}, this.state.rules);
    let rule = rules[ruleId];

    // update bindings
    const bindingIds = Object.keys(rule.bindings);
    let counter = 0;
    for (let bindingId of bindingIds) {
      counter += 1;
      this.setState({ progress: `${counter} of ${bindingIds.length}` });
      let ooxml = await this.getOoxmlInBinding(bindingId);
      if (!ooxml) continue;
      //  save current state in store
      let body;
      body = getBodyFromOoxml(ooxml, bindingId);
      rule.bindings[bindingId].ooxml[rule.selectedToggle] = body;
      await this.toggleSubBindings(bindingId, ooxml, body);
      body = rule.bindings[bindingId].ooxml[toggleId];
      ooxml = wrapBodyInOoxml(ooxml, body);
      await this.setTextInBindingOOXML(bindingId, ooxml, body);
    }

    // Change checked toggle to current toggle
    rule.toggles[rule.selectedToggle].checked = false;
    rule.toggles[toggleId].checked = true;
    rule.selectedToggle = toggleId;

    // Update rule in state
    rules[ruleId] = rule;
    this.setState({ rules: rules }, async () => {
      if (!this.state.isEditing) await this.setDocumentEditable(false);
      this.toggleLoading();
      const diff = new Date() - start;
      console.log(`took ${diff / 1000} seconds`);
    });
  };

  /**
   * Toggle cannotDelete property on sub-bindings in provided ooxml
   * @param {string} bindingId - Tag ID of binding
   * @param {string} ooxml - OOXML with sibbindings
   * @param {string} body - OOXML of just w:body tag
   */
  toggleSubBindings = (bindingId, ooxml, body) => {
    // console.log("DEBUG: toggleSubBindings", bindingId);
    let promise = new Promise((resolve, reject) => {
      if (!ooxml) resolve();
      ooxml = this.fixOoxmlReplace(ooxml, body);
      const subbindings = this.getSubBindings(ooxml, body, bindingId);
      //  no sub-bindings found
      if (!subbindings.length) return resolve();
      //  had sub-bindings
      this.runInWord((context) => {
        const bindings = context.document.contentControls;
        bindings.load("tag, id");
        return context.sync().then(() => {
          const totalBindings = bindings.items.length;
          if (!totalBindings === 0) return resolve();
          for (let i = 0; i < totalBindings; i++) {
            const subId = bindings.items[i].tag;
            if (subbindings.indexOf(subId) !== -1) bindings.items[i].cannotDelete = false;
          }
          return context.sync().then(() => resolve());
        });
      });
    });
    return promise;
  };

  /**
   * Method to get OOXML of a binding
   * @param {string} bindingId - Tag ID of binding
   */
  getOoxmlInBinding = (bindingId) => {
    return new Promise((resolve) => {
      this.runInWord((context) => {
        const contentControls = context.document.contentControls.getByTag(bindingId);
        contentControls.load("id");
        return context.sync().then(() => {
          if (contentControls.items.length === 0) {
            console.log("There isn't a content control in this document.");
            return resolve(null);
          }
          const ooxml = contentControls.items[0].getOoxml();
          return context.sync().then(() => {
            resolve(ooxml.value);
          });
        });
      });
    });
  };

  /**
   * Return InnerHTML from XML element. Required as IE11 does not support innerHTML for non-HTML elements.
   * @param {object} node - DOM node
   * @return {string} HTML String
   */
  getInnerHTML(node) {
    let html = "";
    for (let c = 0; c < node.childElementCount; c++) {
      html = html + this.getOuterHTML(node.childNodes[c]);
    }
    return html;
  }

  /**
   * Return OuterHTML from XML element. Required as IE11 does not support outerHTML for non-HTML elements.
   * @param {object} node - DOM node
   * @return {string} HTML String
   */
  getOuterHTML = (node) => {
    return new XMLSerializer().serializeToString(node);
  };

  /**
   * Set text in binding immediently
   * @param {string} bindingId - Tag ID of the binding
   * @param {string} color - highlight color for binding
   * @param {string} text - text to set in binding
   */
  setTextInBinding = (bindingId, color, text) => {
    // console.info(`DEBUG: App - setTextInBinding`, bindingId, text);
    return new Promise((resolve) => {
      this.runInWord((context) => {
        const docBindings = context.document.contentControls.getByTag(bindingId);
        docBindings.load("id");
        return context.sync().then(() => {
          if (docBindings.items.length === 0) {
            console.log("Binding not found in Document");
            return resolve();
          }
          const binding = docBindings.items[0];
          binding.insertText(text, "Replace");
          return context.sync().then(async () => {
            await this.applyHighlightToBinding(bindingId, color);
            resolve();
          });
        });
      });
    });
  };

  /**
   * Update a binding with provided OOXML
   * @param {string} bindingId - Tag ID of binding to update
   * @param {string} ooxml - ooxml value to set within the binding
   * @param {string} body - ooxml of just w:body tag
   * @param {bool} skipSubBindings - whether to skip updating subbindings or not
   * @param {bool} ignoreDeleteableProperty - whether to set binding on update to be undeletable or not
   * ! This method might be obsolete
   */
  setTextInBindingOOXML = (bindingId, ooxml, body, skipSubBindings, ignoreDeleteableProperty) => {
    // console.log("DEBUG - setTextInBindingOOXML:", bindingId);
    return new Promise((resolve) => {
      ooxml = this.fixOoxmlReplace(ooxml, body);

      const subBindings = this.getSubBindings(ooxml, body, bindingId);
      this.runInWord(async (context) => {
        if (!Office.context.requirements.isSetSupported("WordApi", 1.3)) {
          ooxml = await this.addNumberingPart(ooxml); // Add numbering part for WordApi 1.1 & 1.2
        }
        const docBindings = context.document.contentControls.getByTag(bindingId);
        docBindings.load("id");
        return context.sync().then(
          function () {
            if (docBindings.items.length === 0) {
              console.log("Binding not found in Document App.js:setTextInBindingOOXML");
              return resolve();
            }
            const binding = docBindings.items[0];
            binding.insertOoxml(ooxml, "Replace");
            if (!ignoreDeleteableProperty) binding.cannotDelete = true;
            binding.placeholderText = "";
            return context.sync().then(
              async function () {
                if (subBindings.length && !skipSubBindings) {
                  for (let subBinding of subBindings) await this.updateSubBinding(subBinding);
                }
                resolve();
              }.bind(this)
            );
          }.bind(this)
        );
      });
    });
  };

  /**
   * Get IDs of nested rules in OOXML
   * @param {string} ooxml - OOXML in a rule's binding
   * @param {string} body - OOXML of just w:body tag
   * @param {string} mainBindingId - Tag ID of parent binding
   * @returns {array} Tag IDs of nested bindings
   */
  getSubBindings = (ooxml, body, mainBindingId) => {
    if (!body) {
      body = ooxml;
    } else {
      body = wrapBodyInSmallOoxml(body);
    }
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(body, "text/xml");
    let bindings = xmlDoc.getElementsByTagName("w:tag");
    let ids = [];
    for (let binding of bindings) {
      const bindingId = binding.getAttribute("w:val");
      if (bindingId !== mainBindingId) ids.push(bindingId);
    }
    return ids;
  };

  /**
   * Refresh a sub binding
   * This usually happens when a user toggles a binding that contains a subbinding,
   * which may have changes since the subbinding was not on the page & could have been update
   * @param {string} bindingId - tag ID of the nested binding
   * ! This method might be obsolete
   */
  updateSubBinding = (bindingId) => {
    // console.log("DEBUG: update subbinding called on", bindingId);
    return new Promise(async (resolve) => {
      const { rules } = this.state;
      let toggles = {};
      for (let ruleId in rules) {
        // check rule and quit if the binding doesn't belong to selected rule
        const rule = rules[ruleId];
        const bindings = Object.keys(rule.bindings);
        if (bindings.indexOf(bindingId) === -1) continue;
        // binding is in this rule
        if (rule.type === "text") {
          // for text rule types
          const text = this.getBindingText(rule, bindingId);
          await this.setTextInBinding(bindingId, rule.color, text);
        } else {
          // for toggle rule types
          toggles[bindingId] = rule.selectedToggle;
          let ooxml = await this.getOoxmlInBinding(bindingId);
          const selectedToggle = toggles[bindingId];
          const body = rule.bindings[bindingId].ooxml[selectedToggle];
          ooxml = wrapBodyInOoxml(ooxml, body);
          await this.setTextInBindingOOXML(bindingId, ooxml, body);
        }
      }
      resolve();
    });
  };

  /**
   * Get text for a binding in freetext rule
   * @param {object} rule
   * @param {string} bindingId
   * @returns {string}
   */
  getBindingText = (rule, bindingId) => {
    if (rule.type !== "text") return null;
    for (let entry of Object.values(rule.inputs)) {
      if (entry.bindings.indexOf(bindingId) !== -1) return entry.text;
    }
  };

  /**
   * Fix multiple sdt for same binding in ooxml
   * This is needed with Word 2016 API
   * @param {string} ooxml OOXML in binding
   * @param {string} body Just the w:body tag in ooxml
   * @returns {string}
   */
  fixOoxmlReplace = (ooxml, body) => {
    // console.log("DEBUG: fixOoxmlReplace");
    let parser = new DOMParser();
    if (body) {
      body = wrapBodyInSmallOoxml(body);
    } else {
      body = ooxml;
    }
    let xmlDoc = parser.parseFromString(body, "text/xml");
    let sdt = xmlDoc.getElementsByTagName("w:sdt")[0];
    let container = sdt.parentNode;
    container.removeChild(sdt);

    let content = sdt.getElementsByTagName("w:sdtContent")[0];
    content = this.getInnerHTML(content);

    if (container.tagName !== "w:body") {
      content = `<${container.tagName}>${content}</${container.tagName}>`;
    }
    content = `<w:document xmlns:w='http://schemas.openxmlformats.org/wordprocessingml/2006/main'>
                  <w:body>${content}</w:body>
                </w:document>`;

    const startTag = "<w:document";
    const endTag = "</w:document>";
    const startIndex = ooxml.indexOf(startTag);
    const endIndex = ooxml.indexOf(endTag) + endTag.length;
    ooxml = ooxml.substring(0, startIndex) + content + ooxml.substring(endIndex);
    return ooxml;
  };

  /**
   * Apply a highlight color to a binding
   * @param {string} bindingId - Tag ID of binding to apply color on
   * @param {string} color - 6-digit RGB hexadecimal color code
   * @param {bool} ignoreDeleteableProperty - whether to set binding on update to be undeletable or not
   */
  applyHighlightToBinding = (bindingId, color, ignoreDeleteableProperty) => {
    // console.log("DEBUG: applying color to binding", bindingId, color);
    return new Promise(async (resolve) => {
      let ooxml = await this.getOoxmlInBinding(bindingId);
      if (!ooxml) return resolve();
      let body = getBodyFromOoxml(ooxml);
      body = wrapBodyInSmallOoxml(body);
      body = this.setHighlightColor(body, color);
      body = getBodyFromOoxml(body);
      ooxml = wrapBodyInOoxml(ooxml, body);
      await this.setTextInBindingOOXML(bindingId, ooxml, body, true, ignoreDeleteableProperty);
      resolve();
    });
  };

  /**
   * Update text in OOXML to have provided highlight color
   * @param {string} ooxml - Word OOXML
   * @param {string} color - Highlight color to apply
   */
  setHighlightColor(ooxml, color) {
    // console.log(`DEBUG: App - setHighlightColor`);
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(ooxml, "text/xml");
    let body = xmlDoc.getElementsByTagName("w:body")[0];
    let wr = body.getElementsByTagName("w:r");
    Object.values(wr).forEach((row) => {
      let stylingNodes = row.getElementsByTagName("w:rPr");
      let stylingNode;
      // Check if styling (w:rPr) exists for current node, if not create it
      if (!stylingNodes.length > 0) {
        stylingNode = xmlDoc.createElement("w:rPr");
        row.insertBefore(stylingNode, row.firstChild);
        // console.log("stylingNode");
        // console.log(stylingNode);
      } else {
        stylingNode = stylingNodes[0];
        // console.log("fisrst childstylingNode");
        // console.log(stylingNodes);
      }
      // Check if shading (w:shd) exists for current node, if not create it
      let shadingNodes = stylingNode.getElementsByTagName("w:shd");
      let shadingNode;
      if (!shadingNodes.length > 0) {
        shadingNode = xmlDoc.createElement("w:shd");
        stylingNode.appendChild(shadingNode);
      } else {
        shadingNode = shadingNodes[0];
      }
      // console.log("shadingNode");
      // console.log(shadingNode);
      shadingNode.setAttributeNS(
        "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
        "w:fill",
        color.replace("#", "").toUpperCase()
      );
      shadingNode.setAttributeNS(
        "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
        "w:val",
        "clear"
      );
      shadingNode.setAttributeNS(
        "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
        "w:color",
        "auto"
      );
    });
    let oSerializer = new XMLSerializer();
    let sXML = oSerializer.serializeToString(xmlDoc);
    return sXML;
  }

  setTextInRule = (ruleId, inputId, text) => {
    // console.log("DEBUG: App - setTextInRule");
    if (this.state.rules[ruleId].type !== "text") {
      return console.warn("text method called for toggle rule");
    }
    let rules = Object.assign({}, this.state.rules);
    rules[ruleId].text = text;
    rules[ruleId].inputs[inputId].text = text;
    this.setState({ rules });
  };

  /**
   * Add or edit a rule
   * @param {object} rule - the new or updated rule object passed
   */
  saveRule = (rule) => {
    // console.log(`DEBUG: App - onRuleSaveClick`);
    let ruleText = rule.text
      ? rule.text
      : Object.values(rule.bindings)[0]
      ? Object.values(rule.bindings)[0].text
      : "";
    let rules = Object.assign({}, this.state.rules);
    rule.text = ruleText;
    let ruleId = this.state.editingRuleId ? this.state.editingRuleId : shortid.generate();
    rules[ruleId] = rule;
    this.setState({ rules });
    this.handleChangeView("Template");
  };

  /**
   * Update a rule as received from a sub-component
   * @param {string} ruleId - the ID of rule to update
   * @param {object} rule - the updated rule
   */
  updateRule = (ruleId, rule) => {
    let { rules } = this.state;
    rules[ruleId] = rule;
    this.setState({ rules });
  };

  saveRules = () => {
    // console.log(`DEBUG: App - saveRules`);
    /* eslint-disable */
    Office.context.document.settings.set("rules", this.state.rules);
    saveSettings(this.state.rules);
    /* eslint-enable */
  };

  /**
   * Method to remove a binding and it's highlight color from document
   * @param {string} bindingId - Tag id of the binding to delete
   */
  removeBindingFromWord = (bindingId) => {
    return new Promise(async (resolve) => {
      await this.applyHighlightToBinding(bindingId, "FFFFFF");
      this.runInWord((context) => {
        const docBindings = context.document.contentControls.getByTag(bindingId);
        docBindings.load("font");
        return context.sync().then(() => {
          if (docBindings.items.length === 0) {
            return console.log("Binding not found in Document");
          }
          const binding = docBindings.items[0];
          binding.cannotDelete = false;
          binding.delete(true);
          return context.sync().then(resolve);
        });
      });
    });
  };

  /**
   * Remove background highlight style from selected binding's text
   * @param {string} ooxml - OOXML in binding
   * @param {string} targetColor - the color to remove
   * @returns {string} OOXML without background color
   */
  removeBackgroundColor(ooxml, targetColor) {
    if (targetColor) targetColor = targetColor.toUpperCase().replace("#", "");

    let xmlDoc = new DOMParser().parseFromString(ooxml, "text/xml");
    let shades = xmlDoc.getElementsByTagName("w:shd");

    for (let i = 0; i < shades.length; i++) {
      let shade = shades[i];
      if (targetColor && targetColor !== shade.getAttribute("w:fill")) continue;
      shade.parentNode.removeChild(shade);
    }

    return new XMLSerializer().serializeToString(xmlDoc);
  }

  onRuleRemoveClick = async (ruleId) => {
    // console.info(`DEBUG: App - onRuleRemoveClick`, ruleId);
    this.toggleLoading();
    await this.setDocumentEditable(true);
    let rules = Object.assign({}, this.state.rules);
    const bindingIds = Object.keys(rules[ruleId].bindings);

    this.runInWord((context) => {
      const body = context.document.body;
      const ooxml = body.getOoxml();
      return context.sync().then(() => {
        let contents = ooxml.value.toString();

        const parser = new DOMParser();
        const serializer = new XMLSerializer();
        let xmlDoc = parser.parseFromString(contents, "text/xml");

        let tags = xmlDoc.getElementsByTagName("w:tag");
        for (let tag of tags) {
          const bindingId = tag.getAttribute("w:val");
          if (bindingIds.indexOf(bindingId) === -1) continue;
          // remove highlight
          let wr = tag.parentNode.parentNode.getElementsByTagName("w:r");
          const color = "#FFFFFF";
          Object.values(wr).forEach((row) => {
            let stylingNodes = row.getElementsByTagName("w:rPr");
            let stylingNode;
            // Check if styling (w:rPr) exists for current node, if not create it
            if (!stylingNodes.length > 0) {
              stylingNode = xmlDoc.createElement("w:rPr");
              row.insertBefore(stylingNode, row.firstChild);
            } else {
              stylingNode = stylingNodes[0];
            }
            // Check if shading (w:shd) exists for current node, if not create it
            let shadingNodes = stylingNode.getElementsByTagName("w:shd");
            let shadingNode;
            if (!shadingNodes.length > 0) {
              shadingNode = xmlDoc.createElement("w:shd");
              stylingNode.appendChild(shadingNode);
            } else {
              shadingNode = shadingNodes[0];
            }
            shadingNode.setAttributeNS(
              "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
              "w:fill",
              color.replace("#", "").toUpperCase()
            );
            shadingNode.setAttributeNS(
              "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
              "w:val",
              "clear"
            );
            shadingNode.setAttributeNS(
              "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
              "w:color",
              "auto"
            );
          });
        }
        let newOoxml = serializer.serializeToString(xmlDoc);
        newOoxml = wrapBodyInOoxml(contents, getBodyFromOoxml(newOoxml));

        let lockTag = '<w:lock w:val="sdtContentLocked" />';
        while (newOoxml.indexOf(lockTag) !== -1) {
          newOoxml = newOoxml.replace(lockTag, "");
        }
        lockTag = `<w:lock w:val="contentLocked" />`;
        while (newOoxml.indexOf(lockTag) !== -1) {
          newOoxml = newOoxml.replace(lockTag, "");
        }

        body.insertOoxml(newOoxml, "Replace");
        return context.sync().then(async () => {
          const bindings = context.document.contentControls;
          bindings.load("font, tag, id");
          return context.sync().then(() => {
            const totalBindings = bindings.items.length;
            for (let i = 0; i < totalBindings; i++) {
              if (bindingIds.indexOf(bindings.items[i].tag) !== -1) bindings.items[i].delete(true);
            }
            delete rules[ruleId];
            for (let i in rules) {
              if (rules[i].dependent.rule === ruleId)
                rules[i].dependent = { rule: null, options: [] };
            }
            return context.sync().then(() => {
              this.setState({ rules }, async () => {
                await this.setDocumentEditable(false, true);
                this.toggleLoading();
              });
            });
          });
        });
      });
    });
  };

  handleBackClick(rule) {
    // console.log(`DEBUG: App - handleBackClick`);
    this.handleChangeView("Start");
  }

  handleEditTemplateClick = () => {
    // console.log(`DEBUG: App - handleEditTemplateClick`);
    this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  onRemoveBindings = () => {
    this.setState({ rules: {} });
  };

  toggleHighlights = (show) => {
    this.setState({ showHighlights: show });
    /* eslint-disable no-undef */
    Office.context.document.settings.set("highlights", show);
    Office.context.document.settings.saveAsync();
    /* eslint-enable no-undef */
  };

  /**
   * Handle cancelation of rule creation
   * This logic is execute when user hits back arrow on rule create/edit page
   * @param {object} rule - The rule being canceled
   */
  cancelRuleCreation = (rule) => {
    //  Check if this is for a new rule, not existing one
    if (!this.state.editingRuleId) {
      //  iterate through each bindings
      Object.entries(rule.bindings).forEach(async (binding) => {
        await this.removeBindingFromWord(binding[0]);
      });
    }
    //  Change view back to main page
    this.handleChangeView("Template");
  };

  /**
   * add numbering part to ooxml
   * This is used for WordAPI 1.1 & WordAPI 1.2 to add w:numbering part to OOXML. Required for lists.
   * @param {string} ooxml - The ooxml the w:numbering part should be added to,
   */
  addNumberingPart = async (ooxml) => {
    let numberingPart = await this.getNumberingPart();
    if (numberingPart) {
      // Add required prefix and suffix
      let prefix =
        '<pkg:part pkg:name="/word/numbering.xml" pkg:contentType="application/vnd.openxmlformats-officedocument.wordprocessingml.numbering+xml"><pkg:xmlData>';
      let suffix = "</pkg:xmlData></pkg:part></pkg:package>";
      numberingPart = `${prefix}${numberingPart}${suffix}`;
      ooxml = ooxml.replace("</pkg:package>", numberingPart);

      // Add relationships
      ooxml = ooxml.replace(
        '<pkg:part pkg:name="/word/_rels/document.xml.rels" pkg:contentType="application/vnd.openxmlformats-package.relationships+xml" pkg:padding="256"><pkg:xmlData><Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">',
        '<pkg:part pkg:name="/word/_rels/document.xml.rels" pkg:contentType="application/vnd.openxmlformats-package.relationships+xml" pkg:padding="256"><pkg:xmlData><Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships"><Relationship Id="rId99" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/numbering" Target="numbering.xml"/>'
      );
    }
    return ooxml;
  };
  /**
   * Get w:numbering ooxml part from document
   * This is used for WordAPI 1.1 & WordAPI 1.2 as w:numbering is missing from the regular api.
   * @returns ({string | undefined)} - Returns ooxml string if found or undefiend
   */
  getNumberingPart = async () => {
    let ooxml = await this.getDocumentAsCompressed();
    if (ooxml) {
      let numberingPart = ooxml.split("\n")[1]; // Get the the actualy ooxml we are looking for.
      return numberingPart;
    } else {
      return undefined;
    }
  };

  /**
   * Utility function for getNumberingPart
   * This is used for WordAPI 1.1 & WordAPI 1.2 as w:numbering is missing from the regular api. Only use for getNumberingPart or modify source, wont retrieve other files
   */
  getDocumentAsCompressed = () => {
    return new Promise((resolve) => {
      Office.context.document.getFileAsync(
        Office.FileType.Compressed,
        { sliceSize: 4000000 },
        (result) => {
          if (result.status === "succeeded") {
            // If the getFileAsync call succeeded, then
            // result.value will return a valid File Object.
            var myFile = result.value;
            var sliceCount = myFile.sliceCount;
            var slicesReceived = 0,
              gotAllSlices = true,
              docdataSlices = [];

            // Get the file slices.
            this.getSliceAsync(
              myFile,
              0,
              sliceCount,
              gotAllSlices,
              docdataSlices,
              slicesReceived,
              (ooxml) => resolve(ooxml)
            );
          } else {
            console.warn("ERROR: App.js - getDocumentAsCompressed");
            console.log("Error:", result.error.message);
          }
        }
      );
    });
  };

  /**
   * Utility function for getNumberingPart
   * Used to get a file slice.
   */
  getSliceAsync = (
    file,
    nextSlice,
    sliceCount,
    gotAllSlices,
    docdataSlices,
    slicesReceived,
    callback
  ) => {
    file.getSliceAsync(nextSlice, (sliceResult) => {
      if (sliceResult.status === "succeeded") {
        if (!gotAllSlices) {
          // Failed to get all slices, no need to continue.
          return;
        }

        // Got one slice, store it in a temporary array.
        // (Or you can do something else, such as
        // send it to a third-party server.)
        docdataSlices[sliceResult.value.index] = sliceResult.value.data;
        if (++slicesReceived === sliceCount) {
          // All slices have been received.
          file.closeAsync();
          this.onGotAllSlices(docdataSlices, callback);
        } else {
          this.getSliceAsync(
            file,
            ++nextSlice,
            sliceCount,
            gotAllSlices,
            docdataSlices,
            slicesReceived
          );
        }
      } else {
        gotAllSlices = false;
        file.closeAsync();
        console.log("getSliceAsync Error:", sliceResult.error.message);
      }
    });
  };

  /**
   * Utility function for getNumberingPart
   * Trigger when all slices are retrieved
   */
  onGotAllSlices = (docdataSlices, callback) => {
    var docdata = [];
    for (var i = 0; i < docdataSlices.length; i++) {
      docdata = docdata.concat(docdataSlices[i]);
    }

    var byteArray = new Uint8Array(docdata);
    var blob = new Blob([byteArray]);

    // use a BlobReader to read the zip from a Blob object
    // more files !
    var new_zip = new JSZip();
    new_zip.loadAsync(blob).then(function (zip) {
      // you now have every files contained in the loaded zip
      try {
        new_zip
          .file("word/numbering.xml")
          .async("string")
          .then(function (ooxml) {
            callback(ooxml);
          })
          .catch(function (error) {
            console.log(error);
            // Something went wrong with retrieving the ooxml from numbering part.
            callback();
          });
      } catch (error) {
        // No numbering part was found.
        callback();
      }
    });
  };

  render() {
    // console.log(`DEBUG: App - render`);
    let subpage;

    if (this.state.view === "Template") {
      subpage = (
        <Template
          highlights={this.state.showHighlights}
          toggleLoading={this.toggleLoading}
          onToggleHighlights={this.toggleHighlights}
          setHighlightColor={this.setHighlightColor}
          onRemoveBindings={this.onRemoveBindings}
          setDocumentEditable={this.setDocumentEditable}
          setTextInBinding={this.setTextInBinding}
          isEditing={this.state.isEditing}
          OnEditTemplateClick={this.handleEditTemplateClick}
          onBackClick={() => this.handleChangeView("Start")}
          onRuleTextChange={this.setTextInRule}
          onToggleChange={(ruleId, toggleId) => this.onToggleChange(ruleId, toggleId)}
          onNewRuleClick={this.onNewRuleClick}
          handleChangeView={this.handleChangeView}
          rules={this.state.rules}
          updateRule={this.updateRule}
          onRuleRemoveClick={this.onRuleRemoveClick}
          applyHighlightToBinding={this.applyHighlightToBinding}
          onRuleEditClick={(ruleId) => {
            this.setState({
              editingRuleId: ruleId,
              editingRule: this.state.rules[ruleId],
            });
            this.handleChangeView("NewRuleForm");
          }}
        />
      );
    } else if (this.state.view === "NewRuleForm") {
      subpage = (
        <NewRuleForm
          rules={this.state.rules}
          id={this.state.editingRuleId}
          rule={this.state.editingRule}
          colorCounter={this.state.colorCounter}
          setColorCounter={this.setColorCounter}
          toggleSubBindings={this.toggleSubBindings}
          setDocumentEditable={this.setDocumentEditable}
          removeBindingFromWord={this.removeBindingFromWord}
          setProgress={(progress) => this.setState({ progress })}
          getOuterHTML={this.getOuterHTML}
          getOoxmlInBinding={this.getOoxmlInBinding}
          setHighlightColor={this.setHighlightColor}
          onBackButtonClick={this.cancelRuleCreation}
          onSetTextInBinding={this.setTextInBinding}
          highlights={this.state.showHighlights}
          applyHighlightToBinding={this.applyHighlightToBinding}
          onSetTextInBindingOOXML={this.setTextInBindingOOXML}
          setSpinner={(show) => this.setState({ updating: show })}
          onBackClick={() => {
            this.setState({ showNewRuleForm: false });
          }}
          onRuleSaveClick={this.saveRule}
        />
      );
    } else if (this.state.view === "order") {
      subpage = (
        <ChangeOrder
          rules={this.state.rules}
          onSave={(rules) => this.setState({ rules: rules, view: "Template" })}
          goBack={() => this.setState({ view: "Template" })}
        />
      );
    } else {
      return <div>Need component</div>;
    }

    return (
      <div id='o-wrapper'>
        {subpage}
        <div className='version-info'>v 0.1.21</div>
        <Spinner progress={this.state.progress} updating={this.state.updating} />
      </div>
    );
  }
}

export default App;
