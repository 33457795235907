import React from "react";

/**
 * Helper function to combine inputs and bindings of rules to arrange bindings by input sections
 * @param {object} bindings - all bindings in the rule
 * @param {object} inputs - all inputs for the rule
 * @param {string} selectedInput - The currently selected group/section
 * @returns array of different groups of bindings
 */
const makeSections = (bindings, inputs, selectedInput) => {
  const data = Object.keys(inputs).map(inputId => {
    let _data = [];
    for (let i = 0; i < inputs[inputId].bindings.length; i++) {
      const bindingId = inputs[inputId].bindings[i];
      if (!bindings.hasOwnProperty(bindingId)) continue;
      _data.push({ id: bindingId, text: bindings[bindingId].text });
    }
    return { id: inputId, bindings: _data, selected: selectedInput === inputId };
  });
  return data;
};

/**
 * Component to render text bindings in the rule
 * @param {object} props - React props passed to component
 * @returns JSX
 */
const TextBindingList = props => {
  const sections = makeSections(props.bindings, props.inputs, props.selectedInput);

  const listItems = sections.map((section, index) => {
    const inputs = section.bindings.map((binding, index) => (
      <div
        key={binding.id}
        className="list-group-item list-group-item-action flex-column align-items-start"
        onMouseEnter={() => props.markBinding(binding.id, true)}
        onMouseLeave={() => props.markBinding(binding.id, false)}
      >
        <div className="d-flex w-100 justify-content-between">
          <span className="ms-font-l">{binding.text}</span>
          <button
            onClick={e => props.onRemoveBindingClick(binding.id)}
            type="button"
            className="close pull-right"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    ));

    let sectionTitle;
    if (sections.length > 1) {
      let indicator;
      if (section.selected) {
        indicator = <span className="active text-success">(selected)</span>;
      } else {
        indicator = (
          <button className="btn btn-xs btn-primary" onClick={() => props.selectInput(section.id)}>
            Select
          </button>
        );
      }
      sectionTitle = (
        <div className="section-name">
          Section {index + 1} {indicator}
        </div>
      );
    }

    return (
      <div key={index}>
        {sectionTitle}
        {inputs}
      </div>
    );
  });

  return <div className="list-group">{listItems}</div>;
};

export default TextBindingList;
