import React from "react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Label } from "office-ui-fabric-react/lib/Label";

export default ({ updating, progress }) => {
  if (!updating) return null;
  return (
    <div id="load-status" className="busy">
      <div>
        <Spinner size={SpinnerSize.large} />
        <Label>{`Updating ${progress || ""}`}</Label>
      </div>
    </div>
  );
};
