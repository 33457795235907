import React, { Component } from "react";
import RuleList from "../components/RuleList";
import OutputActions from "../components/OutputActions";

class ViewTemplate extends Component {
  render() {
    let isEditingButtonColorClass = this.props.isEditing ? " text-warning " : " text-white";

    const hasRules = Object.keys(this.props.rules).length > 0;
    const showOutputReadyButtons = hasRules && !this.props.isEditing;

    return (
      <div className="start">
        <div className="duplidoc-purple-bg p-1 d-flex align-items-center">
          <i
            style={{ fontSize: "20px" }}
            className="ms-Icon ms-Icon--ChromeBack duplidoc-purple"
            aria-hidden="true"
          />
          <span
            className={`w-100 p-0 m-0 text-uppercase ms-fontColor-white ms-font-xl text-center`}
            style={{ paddingLeft: "20px" }}
          >
            Duplidocs
          </span>
          <i
            onClick={this.props.OnEditTemplateClick}
            className={`ms-Icon ms-Icon--Settings cog-icon  ${isEditingButtonColorClass}`}
            aria-hidden="true"
          />
        </div>
        <div className="p-0 m-0 text-uppercase ms-fontColor-white duplidoc-purple-light-bg p ms-font-l text-center">
          Fyll i uppgifterna
        </div>

        {Object.values(this.props.rules).length > 0 && (
          <RuleList
            isEditing={this.props.isEditing}
            setTextInBinding={this.props.setTextInBinding}
            onToggleChange={this.props.onToggleChange}
            toggleLoading={this.props.toggleLoading}
            onRuleTextChange={this.props.onRuleTextChange}
            setDocumentEditable={this.props.setDocumentEditable}
            onRuleRemoveClick={this.props.onRuleRemoveClick}
            onRuleEditClick={this.props.onRuleEditClick}
            rules={this.props.rules}
            updateRule={this.props.updateRule}
          />
        )}
        {this.props.isEditing && (
          <div className="m-3">
            <button
              onClick={this.props.onNewRuleClick}
              type="button"
              className="w-100 btn duplidoc-purple-bg text-white btn-sm"
            >
              Skapa regel
            </button>
            <button
              onClick={e => this.props.handleChangeView("order")}
              type="button"
              className="w-100 btn btn-back duplidoc-purple-bg text-white btn-sm"
            >
              Ändra ordning på regler
            </button>
            <button
              onClick={this.props.OnEditTemplateClick}
              type="button"
              className="w-100 btn btn-back duplidoc-purple-bg text-white btn-sm"
            >
              Spara ändringar
            </button>
          </div>
        )}
        {showOutputReadyButtons && <OutputActions {...this.props} />}
      </div>
    );
  }
}

export default ViewTemplate;
