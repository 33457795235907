import React, { Component } from "react";

import Toggles from "./ToggleOptions";

export default class RuleSelector extends Component {
  state = { rules: [], selected: null };

  /**
   * Load rules to select from in state from available rules via props
   */
  componentDidMount() {
    const { rules, id, dependent } = this.props;
    let _rules = [];
    for (let i in rules) {
      if (i === id) continue;
      let rule = rules[i];
      if (rule.type !== "toggle") continue;
      rule.id = i;
      _rules.push(rule);
    }
    this.setState({
      rules: _rules,
      selected: dependent.rule ? rules[dependent.rule] : null
    });
  }

  /**
   * Handle selection of a rule
   * @param {object} event - onchange event
   */
  handleChange = event => {
    let { value } = event.target;
    if (value === "0") {
      this.setState({ selected: null });
    } else {
      const rule = this.props.rules[value];
      this.setState({ selected: rule });
    }
    this.props.update({ rule: null, options: [] });
  };

  /**
   * Component layout
   * @returns {JSX}
   */
  render() {
    const { rules, selected } = this.state;
    if (!rules.length) return null;

    const options = rules.map(rule => (
      <option key={rule.id} value={rule.id}>
        {rule.name}
      </option>
    ));

    return (
      <div>
        <select
          className="w-100 custom-select rule-select"
          onChange={this.handleChange}
          value={selected ? selected.id : "0"}
        >
          <option value="0">Välj regel</option>
          {options}
        </select>
        <Toggles rule={selected} dependent={this.props.dependent} update={this.props.update} />
      </div>
    );
  }
}
