import React, { Component } from "react";
import ToggleBindingsItem from "./ToggleBindingsItem";
import Modal from "./ToggleNameModal";
import DeleteModal from "./ToggleDeleteConfirm";

export default class ToggleBindingsList extends Component {
  state = { editId: null, deleteId: null };

  /**
   * Handle save of toggle name
   * @param {string} updatedName - The new name of the toggle, null if cancel editing
   */
  handleEditSave = updatedName => {
    if (updatedName) this.props.handleToggleNameChange(this.state.editId, updatedName);
    this.setState({ editId: null });
  };

  /**
   * Handle close event on delete confirmation modal for a toggle option
   * @param {boolean} deleteConfirm - If the user confirmed to delete toggle option or not
   */
  handleDeleteClose = deleteConfirm => {
    if (deleteConfirm) this.props.handleToggleDelete(this.state.deleteId);
    this.setState({ deleteId: null });
  };

  /**
   * Component layout
   * @returns {JSX}
   */
  render() {
    const toggles = Object.entries(this.props.toggles);
    const bindings = Object.entries(this.props.bindings);

    const buttons = toggles.map((toggle, index) => {
      let active = toggle[1].checked ? "duplidoc-purple-light-bg" : "";

      return (
        <div className="d-flex toggle-btn-container" key={index}>
          <div
            onClick={() => this.props.onToggleChange(toggle[0])}
            key={toggle[0]}
            className={`btn btn-secondary ${active}`}
          >
            {toggle[1].name}
          </div>
          <div
            className="ml-1 d-flex flex-column justify-content-start"
            style={{ height: "35px", width: "32px" }}
          >
            <button
              className="btn btn-link text-muted p-0 edit-button"
              onClick={e => this.setState({ editId: toggle[0] })}
            >
              Ändra
            </button>
            {!toggle[1].checked && (
              <button
                className="btn btn-link text-muted p-0 edit-button"
                onClick={e => this.setState({ deleteId: toggle[0] })}
              >
                Ta bort
              </button>
            )}
          </div>
        </div>
      );
    });

    let ToggleBindingsList;
    if (Object.keys(bindings).length) {
      ToggleBindingsList = bindings.map((binding, index) => (
        <ToggleBindingsItem
          index={index}
          markBinding={this.props.markBinding}
          onRemoveBindingClick={this.props.onRemoveBindingClick}
          key={index}
          binding={binding}
        />
      ));
    } else {
      ToggleBindingsList = <span>Inga markeringar.</span>;
    }

    return (
      <div className="toggle-list">
        <div className="toggle">
          <h6>Markeringar</h6>
          {ToggleBindingsList}
        </div>
        <hr />
        <span className="p-0 m-0  ms-fontWeight-semibold ms-font-m">
          Vilka knappval skall användaren välja mellan?
        </span>
        <div className="w-100 rule-toggle-group" data-toggle="buttons">
          {buttons}
        </div>

        {this.state.editId && (
          <Modal toggle={this.props.toggles[this.state.editId]} close={this.handleEditSave} />
        )}
        {this.state.deleteId && <DeleteModal close={this.handleDeleteClose} />}
      </div>
    );
  }
}
